import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../Resources/Themes/theme';
// import MetamaskInfo from '../../Components/MetamaskInfo/MetamaskInfo';
import { HashRouter, Route, Routes } from 'react-router-dom';
import MyPlaces from '../MyPlaces/MyPlaces';
import AppStateContext, { MetamaskState } from '../../Contexts/AppStateContext';
import { FeeErrorDialog } from '../Dialogs/FeeErrorDialog';
// import NetworkVersionErrorDialog from '../Dialogs/NetworkVersionErrorDialog';
// import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Defi from '../../Components/Defi/Defi';
import DAO from '../DAO/DAO';
import TheWallTgLoan from '../Defi/TheWallTgLoan/TheWallTgLoan';
import TheWallTgDaily from '../Defi/TheWallTgDaily/TheWallTgDaily';
import Rating from '../Rating/Rating';
import Marketplace from '../Marketplace/Marketplace';
import Instructions from '../../Components/Instructions/Instructions';
import { useStores } from '../../Hooks/useStores';
import { observer } from 'mobx-react-lite';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import QrLinkDialog from '../Dialogs/QrLinkDialog';
import QrArLinkDialog from '../Dialogs/QrArLinkDialog';
import RatingDialog from '../Dialogs/RatingDialog';
import ScrollToTopOnMount from '../../Components/ScrollToTopOnMount/ScrollToTopOnMount';
import { useGetConfigurationQuery } from '../../app/services/apiTgTheWall';
import getAddress from '../../Utils/getAddress';
import TheWallMain from '../TheWall/TheWallMain';
import UpdateContainer from './UpdateContainer';
import Tutorial from '../../Components/Tutorial/Tutorial';
import TutorialData from '../../Components/TutorialData/TutorialData';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { TutorialState } from '../../Stores/TutorialStore';
import TutorialDialog from '../Dialogs/TutorialDialog/TutorialDialog';
import MobileTutorialDialog from '../Dialogs/MobileTutorialDialog/MobileTutorialDialog';
import HeaderProvider from '../../Contexts/HeaderContext';
import RouteWrapper from '../RouteWrapper/RouteWrapper';

const apolloWallClient = new ApolloClient({
  uri: window.config.URI_THEGRAPH_WALL,
  cache: new InMemoryCache()
});

const App = () => {
  const [pluginState, setPluginState] = useState<MetamaskState>(
    MetamaskState.Initial
  );
  const [balanceChangeTimestamp, setBalanceChangeTimestamp] = useState(
    Date.now()
  );
  const [currentAccount, setCurrentAccount] = useState('');
  const [provider, setProvider] =
    useState<Nullable<ethers.providers.Web3Provider>>(null);
  const { wallStore, tutorialStore } = useStores();
  const [readOnly, setReadOnly] = useState(true);
  const { data: wallConfiguration } = useGetConfigurationQuery();

  const setCurProvider = (notNull = true) => {
    if (!currentAccount) return;
    if (provider) {
      window.location.reload();
    }
    if (notNull) {
      setProvider(new ethers.providers.Web3Provider(window.ethereum));
    } else {
      setProvider(null);
    }
  };

  useEffect(() => {
    if (provider) {
      const toAccount = () => {
        setBalanceChangeTimestamp(Date.now());
      };

      const fromAccount = () => {
        setBalanceChangeTimestamp(Date.now());
      };

      const filterToAccount = {
        topics: [null, null, null, ethers.utils.hexZeroPad(currentAccount, 32)]
      };

      const filterFromAccount = {
        topics: [null, null, ethers.utils.hexZeroPad(currentAccount, 32), null]
      };

      provider.on(filterToAccount, toAccount);
      provider.on(filterFromAccount, fromAccount);
    }
  }, [currentAccount, provider]);

  const handleAccountsChanged = (accounts: any) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      setPluginState(MetamaskState.Installed);
      setCurrentAccount('');
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0]);
      setPluginState(MetamaskState.Connected);
      setReadOnly(false);
    }
  };

  const handleChainChanged = () => {
    // We recommend reloading the page, unless you must do otherwise
    window.location.reload();
  };

  const startApp = async () => {
    const provider = await detectEthereumProvider();
    if (!provider) {
      setPluginState(MetamaskState.NotInstalled);
      return;
    }
    // If the provider returned by detectEthereumProvider is not the same as
    // window.ethereum, something is overwriting it, perhaps another wallet.
    if (provider !== window.ethereum) {
      console.error('Do you have multiple wallets installed?');
      setPluginState(MetamaskState.Installed);
      return;
    }
    await window.ethereum.request({ method: 'eth_chainId' });

    window.ethereum.on('chainChanged', handleChainChanged);

    window.ethereum
      .request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      .catch((err: any) => {
        setPluginState(MetamaskState.Installed);
        // Some unexpected error.
        // For backwards compatibility reasons, if no accounts are available,
        // eth_accounts will return an empty array.
        console.error(err);
      });
    window.ethereum.on('accountsChanged', handleAccountsChanged);
  };

  useEffect(() => {
    if (wallConfiguration) {
      wallStore.setCurrentAddress(getAddress());
      wallStore.startApp(wallConfiguration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallConfiguration]);

  useEffect(() => {
    if (wallStore.appStarted) {
      wallStore.getBorder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallStore.appStarted]);

  useEffect(() => {
    if (pluginState !== MetamaskState.Initial) {
      if (
        pluginState === MetamaskState.Connected ||
        pluginState === MetamaskState.Installed
      ) {
        setCurProvider();
      } else {
        setCurProvider(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginState, currentAccount]);

  useEffect(() => {
    startApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    wallStore.setProvider(provider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const disableApp = () => {
    setReadOnly(true);
  };

  const enableApp = () => {
    if (pluginState === MetamaskState.Connected) {
      setReadOnly(false);
    }
  };

  return (
    // <ErrorBoundary>
    <AppStateContext.Provider
      value={{
        pluginState,
        readOnly:
          tutorialStore.tutorialState === TutorialState.ENABLED
            ? false
            : readOnly,
        currentAccount,
        provider,
        disableApp,
        enableApp,
        balanceChangeTimestamp
      }}
    >
      <ApolloProvider client={apolloWallClient}>
        <ThemeProvider theme={theme}>
          <HashRouter>
            <UpdateContainer />
            <Tutorial />
            <TutorialDialog />
            <MobileTutorialDialog />
            <HeaderProvider>
              <Header />
            </HeaderProvider>
            <ScrollToTopOnMount>
              <RouteWrapper>
                <Routes>
                  <Route path="/defi/loan/*" element={<TheWallTgLoan />} />
                  <Route path="/defi/daily/*" element={<TheWallTgDaily />} />
                  <Route path="/defi" element={<Defi />} />
                  <Route path="/governance/*" element={<DAO />} />
                  <Route path="/rating" element={<Rating />} />
                  <Route path="/instructions/*" element={<Instructions />} />
                  <Route path="/my/*" element={<MyPlaces />} />
                  <Route path="/marketplace/*" element={<Marketplace />} />
                  <Route path="/tutorialData" element={<TutorialData />} />
                  <Route path="/:id?" element={<TheWallMain />} />
                </Routes>
              </RouteWrapper>
            </ScrollToTopOnMount>
            <RatingDialog />
          </HashRouter>
          {/* <MetamaskInfo handleAccountsChanged={handleAccountsChanged} /> */}
          <FeeErrorDialog />
          {/* <NetworkVersionErrorDialog /> */}
          <QrLinkDialog />
          <QrArLinkDialog />
        </ThemeProvider>
      </ApolloProvider>
    </AppStateContext.Provider>
    // </ErrorBoundary>
  );
};

export default observer(App);
