import { useState, useEffect, useContext } from 'react';
import { Step } from 'react-joyride';
import axios from 'axios';
import { useQuery } from '../Hooks/useQuery';
import { getUri } from '../Utils/uriUtils';
import AppStateContext, { MetamaskState } from '../Contexts/AppStateContext';
import { isMobile } from 'react-device-detect';

const FIRST_STEP_TEMPLATE = {
  target: 'body',
  content: 'This is the template of the previous step',
  placement: 'center'
};
export interface Action {
  type: 'link' | 'click';
  value: string;
  delay?: string;
  metamaskState: keyof typeof MetamaskState;
}
export interface TutorialStep extends Step {
  actions?: Action[];
}

export const useTutorial = () => {
  const query = useQuery();
  const { pluginState } = useContext(AppStateContext);
  const [steps, setSteps] = useState<Nullable<TutorialStep[]>>();

  const tutorialPath = !isMobile ? '/tutorial.json' : '/mobileTutorial.json';

  useEffect(() => {
    if (pluginState === MetamaskState.Initial) return;
    // To go to a specific step by following the link
    const tutorialStepDataStr = query.get('tutorialStepData');
    if (tutorialStepDataStr) {
      const tutorialStepData = [
        FIRST_STEP_TEMPLATE,
        JSON.parse(tutorialStepDataStr)
      ];
      setSteps(tutorialStepData);
    } else {
      axios.get(getUri(tutorialPath)).then((req: any) => {
        if (req?.data) {
          const data = req.data.filter((i: Action) => {
            if (i?.metamaskState) {
              return MetamaskState[i.metamaskState] === pluginState;
            }
            return i;
          });
          setSteps(data);
        } else {
          throw new Error();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginState]);

  return steps;
};
