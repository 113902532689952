import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Joyride, { Step, ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useTutorial } from '../../Hooks/tutorial';
import { useStores } from '../../Hooks/useStores';
import { TutorialState } from '../../Stores/TutorialStore';
import Tooltip from './Tooltip/Tooltip';
import MobileTooltip from './MobileTooltip/MobileTooltip';
import { isMobile } from 'react-device-detect';
import { TUTORIAL_REMINDER } from './constants';
import { handleZoom } from '../../Containers/TheWall/Elements/renderScene';
import { useGetConfigurationQuery } from '../../app/services/apiTgTheWall';
import { sleep } from '../../Utils/sleep';

const joyrideStyles = isMobile
  ? {
      floater: {
        minWidth: '100%',
        minHeight: '100%',
        zIndex: 1300
      }
    }
  : null;

const getHTMLElement = async (
  selector: string | HTMLElement,
  i = 0
): Promise<HTMLElement> => {
  if (typeof selector === 'object') {
    return Promise.resolve(selector);
  }
  const target = document.querySelector(selector) as HTMLElement;
  if (target) {
    return Promise.resolve(target);
  } else {
    await new Promise(resolve => setTimeout(() => resolve(true), 200));
    if (i > 100) {
      return Promise.reject();
    }
    return getHTMLElement(selector, i + 1);
  }
};

const Tutorial = () => {
  const navigate = useNavigate();
  const { tutorialStore, wallStageStore } = useStores();
  const [run, setRun] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [counter, setCounter] = useState(0); // Used to block buttons while the function setTimeout is triggered
  const tutorials = useTutorial() || [];
  const steps = tutorials as Step[];
  const { stage } = wallStageStore;
  const { data: wallConfiguration } = useGetConfigurationQuery();

  const handleJoyrideCallback = async (data: any) => {
    const { action, index, status, type } = data;

    if (
      tutorialStore.tutorialState !== TutorialState.ENABLED &&
      status === STATUS.RUNNING
    ) {
      tutorialStore.setState(TutorialState.ENABLED);
    }

    if (type === EVENTS.TOUR_END) {
      tutorialStore.setState(TutorialState.DISABLED);
      window.location.assign('/#/?skipTutorial=1');
      window.location.reload();
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      const actions = tutorials[nextIndex]?.actions;

      if (Array.isArray(actions) && actions.length) {
        actions.forEach(async (i, indexI) => {
          if (indexI + 1 === actions.length) {
            if (i.type === 'link') {
              navigate(i.value);
              await getHTMLElement(tutorials[nextIndex].target);
              setStepIndex(nextIndex);
            } else if (i.type === 'click') {
              const element = document.querySelector(i.value) as HTMLElement;
              if (element) {
                element.click();
                await getHTMLElement(tutorials[nextIndex].target);
                setStepIndex(nextIndex);
              } else {
                // If there is no element to click on, then go one step back. Without this, walking in the reverse order broke.
                setStepIndex(nextIndex - 1);
              }
            } else if (i.type === 'scale') {
              const numberOfZoomSteps = 8;
              const millisecondsBetweenZoomSteps = 100;
              for (let n = 0; n < numberOfZoomSteps; n += 1) {
                await sleep(millisecondsBetweenZoomSteps);
                handleZoom({
                  stage,
                  height: wallConfiguration?.wallHeight,
                  width: wallConfiguration?.wallWidth,
                  value: i.value === 'zoomIn' ? false : true,
                  manual: true
                })();
              }
              await getHTMLElement(tutorials[nextIndex].target);
              setStepIndex(nextIndex);
            } else if (i.type === 'scrollInto') {
              // Scrolls the element's ancestor containers such that the element is visible to the user.
              const element = await getHTMLElement(i.value);
              if (element) {
                element.scrollIntoView();
                await getHTMLElement(tutorials[nextIndex].target);
                setStepIndex(nextIndex);
              }
            }
          } else {
            if (i.type === 'link') {
              navigate(i.value);
            } else if (i.type === 'click') {
              const element = document.querySelector(i.value) as HTMLElement;
              if (element) {
                if (i?.delay) {
                  setCounter(prev => prev - 1);
                  setTimeout(() => {
                    element.click();
                    setCounter(prev => prev + 1);
                  }, (indexI + 1) * +i.delay);
                } else {
                  element.click();
                }
              }
            }
          }
        });
      } else {
        setStepIndex(nextIndex);
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      tutorialStore.setState(TutorialState.DISABLED);
      // Need to set our running state to false, so we can restart if we click start again.
      if (index + 1 === steps.length) {
        localStorage.setItem(TUTORIAL_REMINDER, String(Date.now()));
      }
      setRun(false);
    }
  };

  if (tutorialStore.tutorialState !== TutorialState.ENABLED) {
    return null;
  }

  return (
    <div>
      <Joyride
        continuous
        disableOverlayClose
        showProgress
        showSkipButton
        disableScrolling
        run={run}
        steps={steps}
        tooltipComponent={isMobile ? MobileTooltip : Tooltip}
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        hideBackButton={counter !== 0}
        locale={{ last: 'Finish' }}
        floaterProps={{
          hideArrow: isMobile,
          styles: joyrideStyles
        }}
        styles={{
          spotlight: {
            // By default #808080
            backgroundColor: '#929292'
          }
        }}
      />
    </div>
  );
};

export default observer(Tutorial);
