import React, { useState, useEffect } from 'react';
import { Box, Dialog, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useQuery } from '../../../Hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../Hooks/useStores';
import { TutorialState } from '../../../Stores/TutorialStore';
import {
  TIMER_VALUE,
  TUTORIAL_REMINDER
} from '../../../Components/Tutorial/constants';
import exploreOurManual from '../../../Resources/Tutorial/explore_our_manual.png';
import exploreOurManualLater from '../../../Resources/Tutorial/explore_our_manual_later.png';
import s from './MobileTutorialDialog.module.scss';

const MobileTutorialDialog = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const skipTutorial = query.has('skipTutorial');
  const { tutorialStore } = useStores();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true && isMobile);

  useEffect(() => {
    if (skipTutorial && open) {
      tutorialStore.setState(TutorialState.DISABLED);
      navigate('/');
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipTutorial, open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEnableTuturial = () => {
    navigate('/');
    tutorialStore.setState(TutorialState.ENABLED);
    setOpen(false);
  };

  const remindLater = () => {
    // do not show 24 hours (TIMER_VALUE ms)
    setOpen(false);
    localStorage.setItem(TUTORIAL_REMINDER, String(Date.now()));
  };

  const tutorialReminderTimestamp = localStorage.getItem(TUTORIAL_REMINDER);

  if (
    tutorialReminderTimestamp &&
    Date.now() < +tutorialReminderTimestamp + TIMER_VALUE
  ) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      PaperComponent={Box}
      open={open}
      onClose={handleClose}
      className={s.main}
      sx={{
        '& .MuiDialog-paper': {
          margin: 0,
          maxWidth: '100vw',
          maxHeight: '100vh',
          width: 'auto',
          height: '100%',
          overflow: 'visible'
        }
      }}
    >
      <Box className={s.wrapper}>
        <Box>
          <Typography className={s.content}>
            <Trans i18nKey="we_invite_you_to_take_a_basic_tutorial_mobile">
              We invite you to a journey along the <span>Wall</span>
            </Trans>
          </Typography>
          <Box className={s.triangle} />
        </Box>
        <Box className={s.buttons}>
          <Box className={s.cancel}>
            <img src={exploreOurManualLater} alt="" onClick={remindLater} />
          </Box>
          <Box className={s.start}>
            <img src={exploreOurManual} alt="" onClick={handleEnableTuturial} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MobileTutorialDialog;
