import React from 'react';
import { Box, Button, Typography, ButtonProps } from '@mui/material';
import { TooltipRenderProps } from 'react-joyride';
import s from './MobileTooltip.module.scss';

interface TooltipBodyProps {}
const TooltipBody = React.forwardRef<HTMLDivElement, TooltipBodyProps>(
  (props, ref) => {
    return (
      <div ref={ref}>
        <Box className={s.tooltip}>
          {(props as { children: React.ReactNode }).children}
        </Box>
      </div>
    );
  }
);

const TooltipContent = ({ ...props }) => {
  return (
    <Box>
      <Box className={s.content}>{props.children}</Box>
      <Box className={s.triangle} />
    </Box>
  );
};

const TooltipFooter = ({ ...props }) => {
  return <Box className={s.tooltipFooter}>{props.children}</Box>;
};

const TooltipTitle = ({ ...props }) => {
  return <Box>{props.children}</Box>;
};

const ButtonControl = ({
  color,
  variant,
  disabled,
  onClick,
  ...props
}: ButtonProps) => {
  return (
    <Button
      {...props}
      disableFocusRipple
      color={color}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
    >
      {props.children}
    </Button>
  );
};

const MobileTooltip = ({
  continuous,
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps
}: TooltipRenderProps) => {
  const disabled = step.hideBackButton;
  return (
    <TooltipBody {...tooltipProps}>
      {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
      <TooltipContent>
        <Typography
          dangerouslySetInnerHTML={{ __html: step.content as string }}
        />
      </TooltipContent>
      <TooltipFooter>
        <Box className={s.controlTop}>
          <ButtonControl {...skipProps} disabled={disabled}>
            {closeProps.title}
          </ButtonControl>
        </Box>
        <Box className={s.controlButtom}>
          {continuous && (
            <ButtonControl {...primaryProps} disabled={disabled}>
              <Typography variant="inherit">
                {primaryProps.title}&nbsp;&nbsp;&nbsp;
              </Typography>
              <Typography variant="inherit" fontSize={16}>
                {index + 1}/{size}
              </Typography>
            </ButtonControl>
          )}
        </Box>
      </TooltipFooter>
    </TooltipBody>
  );
};

export default MobileTooltip;
